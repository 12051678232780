<template>
  <el-row>
    <el-col :ld="8" :md="12" :sm="18" :xs="20" class="account">
        <div class="wrap-username">
          <h3>{{ userAlias }}</h3>
          <p>{{ userName }}</p>
        </div>
        <ul>
          <li class="wrap-td">
            <span>登录名</span>
            <span>{{ userName }}</span>
          </li>
          <li class="wrap-td">
            <span>用户别名</span>
            <span class="wrap-user">{{ userAlias }}</span>
          </li>
          <li class="wrap-td">
            <span>部门</span>
            <span class="wrap-user">{{ departMentName }}</span>
          </li>
          <li class="wrap-td">
            <span>密码</span>
            <router-link to="/Account/ChangePassword">[更改密码]</router-link>
          </li>
          <li class="wrap-td">
            <span>电话号码</span>
            <router-link to="/Account/ChangePhoneNum"
              >[{{ phoneNum }}]</router-link
            >
          </li>
          <li class="wrap-td">
            <span>签名</span>
             <el-upload
              list-type="picture"
              class="avatar-uploader"
              action=""
              accept=".jpg,.jpeg,.png,.JPG,.PNG"
              :auto-upload="false"
              :file-list="fileList"
              :on-change="getFile"
              :show-file-list="false"
              >
               <img :src="`data:image/png;base64,`+imageUrl" v-if="imageUrl != undefined && imageUrl.length!=0" style="height:40px;"  alt="" />
               <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
            </el-upload>
          </li>
        </ul>
    </el-col>
  </el-row>
</template>

<script>
import { Plus } from '@element-plus/icons';
import { ElMessage } from "element-plus";
export default {
  components: {
    Plus,
  },
  data() {
    return {
      userName: '',
      userAlias: '',
      phoneNum: '',
      imageUrl: '',
      departMentName: '',
      fileList:[],
    }
  },
  methods: {
    getFile(file) {
      this.upData(file.raw)
    },
    //  文件上传
    upData(file) {
      let me = this;
      var data = new FormData();
      // 将需要添加的参数添加进表单中
      data.append("file", file)//获取需要上传的文件
      let headers = { headers: { "Content-Type": "multipart/form-data" } }
      this.$ajax.post(me.$appConfig.apiUrls.userApi + "/api/User/UpdateSignature", data, headers)
        .then(async response => {
          if (response.data.IsSuccess) {
            await me.$store.dispatch('getUserInfo');
            me.imageUrl = me.$store.state.userInfo.Signature;
            ElMessage({
              type: "success",
              message: "上传签名成功",
            });
          } else {
            ElMessage({
              type: "warning",
              message: response.data.ErrorMessage,
            });
          }
        })
        .catch(err => {
          ElMessage({
            type: "error",
            message: err,
          });
        })
    },
  },
  activated() {
    if(this.$router.user){
      this.userName = this.$router.user.profile.Username;
      this.userAlias = this.$router.user.profile.name;
    }
    if(this.$store.state.userInfo != null && this.$store.state.userInfo.length != 0){
      this.imageUrl = this.$store.state.userInfo.Signature;
      this.departMentName = this.$store.state.userInfo.DepartmentName;
      this.phoneNum = this.$store.state.userInfo.PhoneNumber;
    }
  },
}
</script>

<style scoped>
.account {
  background-color: #fff;
  padding: 10px;
  /* min-width: 400px; */
}
.account .wrap-username {
  text-align: center;
}
.account .wrap-username h3 {
  font-size: 21px;
  font-weight: normal;
  margin-bottom: 10px;
}
.account .wrap-username p {
  font-size: 14px;
  margin-bottom: 10px;
  color: #777777;
}
.account .wrap-td {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.42857143;
  border-top: 1px solid #e0e0e0;
  border-bottom: 0px;
  font-size: 14px;
  padding: 10px 0px;
}
.account .wrap-td .wrap-user {
  color: #3c8dbc;
}
.account .wrap-td .wrap-user:hover {
  color: #72a8c7;
}
.account .wrap-td span:first-child {
  font-weight: bold;
}
.account ul li:last-child {
  border-bottom: 1px solid #e0e0e0;
}
/* .el-upload img{
  height: 50px;
} */
:deep(.avatar-uploader) {
  height: 40px;
}
:deep(.avatar-uploader .el-upload) {
  border: 1px dashed #d9d9d9;
  height: 40px;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
}
:deep(.avatar-uploader .el-upload:hover) {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 14px;
  color: #8c939d;
  width: 40px;
  /* height: 178px; */
  text-align: center;
}
.avatar-uploader-icon svg {
  margin-top: 14px;
}

</style>